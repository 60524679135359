<template>
  <div class="fill-heigt iframe-placeholder"  >
    <v-container class="fill-heigt" fluid>
      <v-row class="pb-0">
        <v-btn icon color="primary" @click="routerBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
       
      </v-row>
    </v-container>
    <div class="afinar" >
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="this.currentUser.candidate.id"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :html-to-pdf-options="htmlToPdfOptions"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
      </vue-html2pdf>
    </div>
    <ShareCVDialog />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API_URL } from "@/api";
import ShareCVDialog from "../components/ShareCVDialog.vue";
import VueHtml2pdf from "../../../components/vue-html2pdf.vue";
export default {
  name: "CV",
  data: () => ({
    apiUrl: API_URL,
    publicPath: process.env.BASE_URL,
    path: "",
    htmlToPdfOptions: {
      margin: 0,

      // filename: `hehehe.pdf`,

      image: {
        type: "jpeg",
        quality: 2,
      },

      enableLinks: true,

      html2canvas: {
        scale: 5.5,
        useCORS: true,
      },
      // pagebreak: { mode: "avoid-all" },
      jsPDF: {
        format: "letter",
        floatPrecision: "smart",
        orientation: "portrait",
      },
    },
  }),
  components: { ShareCVDialog, VueHtml2pdf, /*cvhtml*/ },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      trainingAreas: "library/trainingAreas",
    }),
    candidate() {
      return this.currentUser ? this.currentUser.candidate : {};
    },
    
  },
  methods: {
    
    routerBack() {
      this.$router.push({ path: `/candidate/candidate/profile` });
    },
    openShareDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareCandidateCV", this.candidate);
    },
  },
  mounted() {
   
  },
};
</script>
<style scoped>

.afinar {
  max-width: 800px;
  align-content: center;
  margin: auto;
}
</style>
